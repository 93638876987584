import React from "react";
import {
    CardTitle,
    CardBody,
    Container,
    TabletCol,
    Row
} from "ui-library";
import { IconButton } from '../../../IconComponent';


let PowerBIConsumerTraining = () => {

    let consumertraining = require('./ConsumerTraining.json');
    const { OverviewTitle_Prerequisites, OverviewText_Prerequisites, TrainingLinks_Prerequisites, SecondaryText_Prerequisites } = consumertraining[0];
    const { OverviewTitle, OverviewText } = consumertraining[1];

    return (
        <>
            <CardTitle title={OverviewTitle_Prerequisites} alignment="left" />

            <CardBody alignment="left">

                <p> {OverviewText_Prerequisites} </p>

                <p>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Vqz2d7pTOV8?si=fc9xW-J_daZWJhx8?hl=es&amp;cc_lang_pref=es&amp;cc_load_policy=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                    {TrainingLinks_Prerequisites.map((link) => {
                        return <IconButton item={link} cssClass={'resourceIcon'} iconSize={28} />;
                    })}
                </p>

                <p dangerouslySetInnerHTML={{ __html: SecondaryText_Prerequisites }} />

            </CardBody>
            <CardTitle title={OverviewTitle} alignment="left" />
            <CardBody alignment="left">
                <p dangerouslySetInnerHTML={{ __html: OverviewText }} />
            </CardBody>
        </>

    )
}

export { PowerBIConsumerTraining };